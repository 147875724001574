import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Form from '../components/form/index';
import Service from '../components/service';

const IndexPage = ({ location }) => (
    <Layout location={location}>
        <Seo
            title="MediCareMaster"
            description="If You Are Over 64 Then You Qualify For Medicare. We Help You Find The Right Coverage For You In Your Area From Trusted Providers."
            image="http://medicaremaster.org/src/images/slide03.jpg"
        />
        <Form />
        <Service />
    </Layout>
);

export default IndexPage;
