import React from 'react';
import { Formik, Form } from 'formik';
import FormWrapper from '../fromWrapper';
import FirstName from '@torchlight-technology/name-first-field';
import LastName from '@torchlight-technology/name-last-field';
import Email from '@torchlight-technology/email-field';
import Address from '@torchlight-technology/home-street-field';
import Zip from '@torchlight-technology/zip-field';
import City from '@torchlight-technology/home-city-field';
import State from '@torchlight-technology/home-state-field';
import Phone from '@torchlight-technology/phone-home-field';
import DateOfBirth from '../components/dateOfBirth';
import Gender from '@torchlight-technology/gender-field';
import { Link } from 'gatsby';
import { checkTwilioPhone } from '../formFunctions'

const StepOne = ({
    formData,
    dispatchSetField,
    dispatchSetHomeCity,
    dispatchClearHomeCity,
    meta
}) => {
    return (
        <FormWrapper>
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                onSubmit={(values) => {
                    checkTwilioPhone(values, meta);
                }}
            >
                {(props) => (
                    <Form>
                        <div className="step-one-container">
                            <div className="step-one">
                                <h2>Get Your Free Quote</h2>
                                <div className="form-block">
                                    <FirstName
                                        {...props}
                                        placeholder="FIRST NAME"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        noErrorMessage
                                    />
                                    <LastName
                                        {...props}
                                        placeholder="LAST NAME"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        noErrorMessage
                                    />
                                </div>
                                <div className="form-block">
                                    <Email
                                        {...props}
                                        placeholder="EMAIL"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        noErrorMessage
                                    />
                                    <Address
                                        {...props}
                                        placeholder="ADDRRESS"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        noErrorMessage
                                    />
                                </div>
                                <div className="form-block three">
                                    <Zip
                                        {...props}
                                        placeholder="ZIP CODE"
                                        labelText=" "
                                        setField={dispatchSetField}
                                        noErrorMessage
                                        setHomeCityState={dispatchSetHomeCity}
                                        clearHomeCityState={dispatchClearHomeCity}
                                    />
                                    <City
                                        {...props}
                                        placeholder="CITY"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        noErrorMessage
                                    />
                                    <State
                                        {...props}
                                        placeholder="STATE"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        noErrorMessage
                                    />
                                </div>
                                <div className="form-block three">
                                    <Phone
                                        {...props}
                                        placeholder="PHONE NUMBER"
                                        labelText=" "
                                        setField={dispatchSetField}
                                        noErrorMessage
                                        setHomeCityState={dispatchSetHomeCity}
                                        clearHomeCityState={dispatchClearHomeCity}
                                    />
                                    <DateOfBirth
                                        {...props}
                                        placeholder="MM/DD/YYYY"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        noErrorMessage
                                    />
                                    <Gender
                                        {...props}
                                        placeholder="GENDER"
                                        setField={dispatchSetField}
                                        labelText=" "
                                        type="select"
                                        noErrorMessage
                                    />
                                </div>
                                <div className="submit-button">
                                    <button type="submit">Get My Quote Now</button>
                                </div>
                                <div className="disclaimer">
                                    <label id="disclaimer">
                                        <input type="hidden" id="leadid_tcpa_disclosure" />
                                        By completing the contact form above and clicking "Get My Quote Now", you provide an electronic signature agreeing to the following: "I consent to receive emails and calls about Medicare coverage options, including Medicare Advantage or Medicare Supplement Insurance (which may be auto-dialed, use artificial or pre-recorded voices, and/or be text messages) from <a href="http://blipmarketing.co/partners.php#health" target="_blank">these companies</a> and their agents to the email address and telephone number(s) I have provided (even if these numbers are wireless or on a government do-not-call registry). I understand that my consent to receive calls is not required in order to purchase any property, goods or services. My telephone company may impose additional charges for messages. I may revoke my consent to receiving messages at any time. By submitting my information, I confirm that I understand and agree to these <Link to='/terms'>Terms of Use</Link> and <Link to='/privacy'>Privacy Policy</Link>." You will be directed to a licensed insurance agent who can answer your questions and provide information about Medicare Supplement Insurance Plan, Medicare Advantage and/or Prescription Drug Plan options. Licensed insurance agents are not connected with or endorsed by the U.S. government or the federal Medicare program.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </FormWrapper>
    );
};

export default StepOne;
