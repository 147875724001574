import React from 'react';
import BannerSave from '../images/banner-save-300x350.jpg';
import BannerEasy from '../images/banner-easy-300x350.jpg';
import BannerSupport from '../images/banner-support-300x350.jpg';

const Service = () => {
    return (
        <div className="service-container">
            <div className="service">
                <h3>MediCareMaster.org is committed to finding you the best healthcare plan for your needs.</h3>
                <h5>Our goal is to make getting a Medicare Supplement Insurance plan simple and easy!</h5>
                <div className="articles">
                    <article>
                        <img src={BannerSave} alt="Save" />
                        <p>Save money with our affordable Medicare Supplement Insurance plans</p>
                    </article>
                    <article>
                        <img src={BannerEasy} alt="Easy" />
                        <p>Free quotes from a simple, no hassle 1 page form</p>
                    </article>
                    <article>
                        <img src={BannerSupport} alt="Save" />
                        <p>Direct access to agents, helping you choose the best plan for you</p>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default Service;
