import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StepOne from './steps/StepOne';
import {
  setField,
  setHomeCityState,
  clearHomeCityState,
} from '../../state/action';

const OnePingTreeForm = () => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.page.pageIndex);
  const formState = useSelector((state) => state.formState);
  const meta = useSelector((state) => state.meta);

  const dispatchSetField = (field) => {
    dispatch(setField(field));
  };

  const dispatchSetHomeCity = (data) => {
    dispatch(setHomeCityState(data));
  };

  const dispatchClearHomeCity = (data) => {
    dispatch(clearHomeCityState(data));
  };

  switch (page) {
    case 1:
      return (
        <StepOne
          formData={formState}
          dispatchSetField={dispatchSetField}
          dispatchSetHomeCity={dispatchSetHomeCity}
          dispatchClearHomeCity={dispatchClearHomeCity}
          meta={meta}
        />
      );
    default:
      return <h1>Thank You</h1>;
  }
};

export default OnePingTreeForm;
