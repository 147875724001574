import React from 'react';

const FormWrapper = ({ children }) => {
    return (

        <>
            <div className="form-container">
                <div className="form">{children}</div>
                <div className="header-headline-desktop">
                    <h5>If You Are Over 64 Then You Qualify For Medicare. We Help You Find The Right Coverage For You In Your Area From Trusted Providers.</h5>
                </div>
            </div>
           <div className="form-headline">
                <div className="header-headline-mobile">
                <h5>If You Are Over 64 Then You Qualify For Medicare. We Help You Find The Right Coverage For You In Your Area From Trusted Providers.</h5>
            </div>
           </div>
        </>
    );
};

export default FormWrapper;
