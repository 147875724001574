import React from 'react';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import { createAutoCorrectedDatePipe } from '../../../helper';

const dob = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', {
  minYear: '1910',
  maxYear: '2002',
});

// DATE OF BIRTH VALIDATION
const validate = (values) => {
  if (!values) {
    return 'Required';
  } else if (values.replace(/[^0-9]/g, '').length < 8) {
    return 'Date must be at 8 characters long';
  }

  return '';
};

const DateOfBirth = (props) => (
  <div className="ttg-field">
    {props.labelText && <label htmlFor="dob">{props.labelText}</label>}
    <Field
      name="dob"
      validate={validate}
      render={({ field }) => (
        <MaskedInput
          {...field}
          mask={dob}
          placeholder={props.placeholder ? props.placeholder : 'MM/DD/YYYY'}
          type="text"
          guide={false}
          pipe={autoCorrectedDatePipe}
          className={`field ${
            props.errors.dob && props.touched.dob ? 'has-error' : ''
          }`}
          onChange={(e) => {
            props.setField(e);
            props.handleChange(e);
          }}
        />
      )}
    />
  </div>
);

export default DateOfBirth;
