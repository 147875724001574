/* eslint-disable no-console */
import axios from 'axios';
import { navigate } from 'gatsby';
import moment from 'moment';

export const claimTrustedForm = (trusted_form_url) => {
  const data = `action=claim&payload={"url":"${trusted_form_url}","vertical":"${process.env.GATSBY_VERTICAL}"}`;
  axios
    .post(
      `${process.env.GATSBY_TRUSTED_FORM_URL}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
    .then((res) => console.log('trusted from response: ', res.data))
    .catch((error) => console.log(error));
};

export const convertPhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/\D/g, '');

export const getLastFourPhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/\D/g, '').split('').splice(6, 10).join('');

export const postToOnePingTree = (values, meta) => {
  // assign meta values to form
  const {
    session_id,
    user_agent,
    ip_address,
    sub_id1,
    sub_id2,
    sub_id3,
    sub_id4,
    client_name,
    tcpa_disclosure,
    landing_url,
    privacy_policy_url,
    offer_id,
  } = meta;

  const dateOfBirth = values.dob;

  values.session_id = session_id;
  values.user_agent = user_agent;
  values.offer_id = offer_id;
  values.ip_address = ip_address;
  values.sub_id1 = sub_id1;
  values.sub_id2 = sub_id2;
  values.sub_id3 = sub_id3;
  values.sub_id4 = sub_id4;
  if (sub_id4) values.sub_id4 = sub_id4;
  values.client_name = client_name;
  values.tcpa_disclosure = tcpa_disclosure;
  values.landing_url = landing_url;
  values.privacy_policy_url = privacy_policy_url;
  values.datetime_collected = moment().format('YYYY-MM-DD hh:mm:ss');
  values.dob = `${values.dob.substr(6, 4)}-${values.dob.substr(
    0,
    2,
  )}-${values.dob.substr(3, 2)}`;

  // get disclaimer from label under submit button
  const node = document.getElementById('disclaimer');
  const tcpa_consent_language = node.textContent;
  values.tcpa_consent_language = tcpa_consent_language;

  values.phone_home = convertPhoneNumber(values.phone_home);
  values.phone_last4 = getLastFourPhoneNumber(values.phone_home);

  // assign trusted from url, get value from trusted from script on template.html
  const trusted_form_url_value =
    document.getElementById('trusted_form_url_0').value;
  values.trusted_form_url = trusted_form_url_value;

  // assign universal leadid, get value from universal leadid script on html.js
  values.universal_leadid = document.querySelector('#leadid_token').value;

  console.log("Values---->", values)

  // POST to one.pingtreetech
  const getResponse = (process.env.GATSBY_QA && JSON.parse(process.env.GATSBY_QA)) ? '?response=1' : '';
  fetch(`${process.env.GATSBY_API}${getResponse}`, {
    method: "POST",
    headers: {
      Authorization: `${process.env.GATSBY_API_TOKEN}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
    .then((res) => res.json())
    .then((postResults) =>
      console.log('opt response: ', JSON.stringify(postResults)),
    )
    .catch((err) => {
      process.env.GATSBY_STAGE === 'staging' &&
        console.log('opt response error: ', err);
    });

  navigate(`/thank-you`, {
    state: { values },
  });
  // assigning already saved dateOfBirth value to current dob value
  values.dob = dateOfBirth;

  // Claim Trusted form
  // claimTrustedForm(values.trusted_form_url);
};

export const checkTwilioPhone = (values, meta) => {
  const data = { phone: convertPhoneNumber(values.phone_home) };
  axios
    .post(`${process.env.GATSBY_TWILIO_API}`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((res) => {
      if (!res.data.success) meta.sub_id4 = 'bad_phone';
    })
    .then(() => {
      postToOnePingTree(values, meta);
    })
    .catch((error) => {
      console.log(error);
      postToOnePingTree(values, meta);
    });
};
